import React from 'react'
import Header from './header'
import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <a href='https://grossyoan.fr'>Portfolio</a>
          <a href='https://twitter.com/grossyoan'>Twitter</a>
          <a href='https://www.instagram.com/yoan_gross/'>Instagram</a>
          <a href='https://www.linkedin.com/in/yoan-gross-038146153/'>LinkedIn</a>
          <a href='https://github.com/grossyoan'>Github</a>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
